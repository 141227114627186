import React from 'react';
import imageSource from '../../../images/testimonial/tes3-company.webp';
import imageMain from '../../../images/testimonial/tes3-company.png';

const Testimonial3CompanyImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" alt="CompanyLogo" style={{ 'width': '125px' }} src={imageMain}/>
    </picture>
  );
};

export default Testimonial3CompanyImage;
