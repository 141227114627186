import React from 'react';
import imageMain from '../../../images/integration/kopano-logo.svg';

const KopanoLogo = () => {
  return (
    <img loading="lazy" alt="Kopano" title="Kopano" className="image--xs" height="50" width="155" src={imageMain}/>
  );
};

export default KopanoLogo;
