import React from 'react';
import imageSource from '../../../images/integration/zapier-logo.webp';
import imageMain from '../../../images/integration/zapier-logo.png';

const ZapierIntegrationImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" alt="Zapier" title="Zapier" className="image--xs" src={imageMain}/>
    </picture>
  );
};

export default ZapierIntegrationImage;
