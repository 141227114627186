import React from 'react';
import imageMain from '../../../images/integration/msteams.svg';

const MsTeamsImage = () => {
  return (
    <img loading="lazy" alt="Microsoft Teams" title="Microsoft Teams" className="image--xs" height="80" src={imageMain}/>
  );
};

export default MsTeamsImage;
