import React from "react"
import OmidyarLogoImage from "../image/brand/omidyarLogoImage"
import DhostingcomLogoImage from "../image/brand/dhostingcomLogoImage"
import RocketreachLogoImage from "../image/brand/rocketreachLogoImage"
import DominosLogoImage from "../image/brand/dominosLogoImage"
import OracleLogoImage from "../image/brand/oracleLogoImage"

import CustomerLogo from "../image/brand/customerLogo"

import stanfordLogo from "../../images/brand/stanford.svg"
import rocketreachLogo from "../../images/brand/rocketreach.svg"
import oracleLogo from "../../images/brand/oracle.svg"
import dominosPizzaLogo from "../../images/brand/dominospizza.svg"
import dhostingLogo from "../../images/brand/dhostingcom.svg"

const Brands = () => {
  return (
    <section className="py-5 py-md-11 bg--secondary">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-lg-7 text-center">
            <h2 style={{ fontSize: "26px" }}>Great teams trust Harmonizely</h2>
          </div>
        </div>
        <div className="row align-items-center justify-content-center">
          <div className="col-5 col-md-2">
            <div className="img-fluid img-fluid mb-6 mb-md-8 svg-shim">
              <CustomerLogo
                altText="Dhosting"
                title="Dhosting"
                src={dhostingLogo}
              />
            </div>
          </div>
          <div className="col-5 col-md-2">
            <div className="img-fluid img-fluid mb-6 mb-md-8 svg-shim">
              <CustomerLogo altText="Oracle" title="Oracle" src={oracleLogo} />
            </div>
          </div>
          <div className="col-5 col-md-2">
            <div className="img-fluid img-fluid mb-6 mb-md-8 svg-shim text-center">
              <CustomerLogo
                altText="RocketReach"
                title="RocketReach"
                height={50}
                src={rocketreachLogo}
              />
            </div>
          </div>
          <div className="col-5 col-md-2">
            <div className="img-fluid img-fluid mb-6 mb-md-8 svg-shim">
              <CustomerLogo
                altText="Domino's Pizza"
                title="Domino's Pizza"
                src={dominosPizzaLogo}
              />
            </div>
          </div>
          <div className="col-5 col-md-2">
            <div className="img-fluid img-fluid mb-6 mb-md-8 svg-shim">
              <CustomerLogo
                altText="Stanford University"
                title="Stanford University"
                src={stanfordLogo}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Brands
