import React from 'react';
import imageMain from '../../../images/integration/facebook-pixel.png';
import imageSource from '../../../images/integration/facebook-pixel.webp';

const FacebookPixelImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" alt="Facebook Pixel" title="Facebook Pixel" className="image--md" src={imageMain}/>
    </picture>
  );
};

export default FacebookPixelImage;
