import React from 'react';
import PropTypes from 'prop-types';

const Testimonial = ({ testimonialText, personName, personCompany, personImage, companyLogo, children }) => {
  return (
    <section>
  <div className="container">
    <div className="row">
      <div className="col-md-12">
      <div className="testimonial row justify-content-center">
        <div className="col-lg-2 col-md-4 col-6 text-center">
          {personImage}
        </div>
        <div className="col-lg-7 col-md-8 col-12">
          <span className="h3">
            &ldquo;{children}&rdquo;
            
          </span>
          <h1 style={{fontWeight: "bold"}} className="h4">{personName}, <span>{personCompany}</span></h1>
          {companyLogo ? companyLogo : null}


        </div>
      </div>
      </div>
      </div>
      </div>
      </section>
  );
};

Testimonial.propTypes = {
  //testimonialText: PropTypes.string.isRequired,
  personName: PropTypes.string.isRequired,
  personCompany: PropTypes.string.isRequired,
  personImage: PropTypes.element.isRequired,
  companyLogo: PropTypes.element,
};

export default Testimonial;
