import React from 'react';
import imageSource from '../../images/lead.webp';
import imageMain from '../../images/lead.png';

const LeadImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" className="unmarg--bottom" alt="Harmonizely Dashboard" src={imageMain}/>
    </picture>
  );
};

export default LeadImage;
