import React from 'react';
import imageSource from '../../../images/feature/seamless.webp';
import imageMain from '../../../images/feature/seamless.png';

const SeamlessFeatureImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" className="bg--dark box-shadow-wide" alt="Image" src={imageMain}/>
    </picture>
  );
};

export default SeamlessFeatureImage;
