import React from 'react';
import imageSource from '../../../images/integration/icloud.webp';
import imageMain from '../../../images/integration/email_icloud.svg';

const IcloudIntegrationImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" alt="iCloud Calendar" title="iCloud Calendar" className="image--xs" height="30" src={imageMain}/>
    </picture>
  );
};

export default IcloudIntegrationImage;
