import React from 'react';
import imageSource from '../../../images/integration/zimbra.webp';
import imageMain from '../../../images/integration/zimbra.png';

const ZimbraIntegrationImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" alt="Zimbra" title="Zimbra" className="image--xs" src={imageMain}/>
    </picture>
  );
};

export default ZimbraIntegrationImage;
