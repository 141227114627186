import React from 'react';
import imageSource from '../../../images/integration/email_fruux.webp';
import imageMain from '../../../images/integration/email_fruux.svg';

const EmailFruuxIntegrationImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" alt="Fruux" title="Fruux" className="image--xxs" src={imageMain}/>
    </picture>
  );
};

export default EmailFruuxIntegrationImage;
