import React from 'react';
import imageSource from '../../../images/integration/evidence.webp';
import imageMain from '../../../images/integration/evidence.svg';

const EvidenceIntegrationImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" alt="Evidence" title="Evidence" className="image--xs" height="45" src={imageMain}/>
    </picture>
  );
};

export default EvidenceIntegrationImage;
