import React from 'react';
import PropTypes from 'prop-types';
import DeviceImage from '../image/deviceImage';

class Feature extends React.Component {
    render() {
        const {featureName, featureImage, isSecondary, additionalClassName, hideDeviceImage, hideCtaButton, leadClass} = this.props;

        return (
            <section
                className={`switchable feature-large ${isSecondary ? 'switchable--switch' : ''} ${additionalClassName}`}>
                <div className="container">
                    <div className={isSecondary ? 'row' : 'row justify-content-around'}>
                        <div className="col-md-6">
                            {hideDeviceImage ?  null : <DeviceImage/>}
                            {featureImage}
                        </div>
                        <div className="col-md-6">
                            <div>
                                <h2>{featureName}</h2>
                                <p className={leadClass ? leadClass : 'lead'}>{this.props.children}</p>
                                {hideCtaButton ? null : <a className="btn btn--lg btn--primary btn--black" href={process.env.REGISTER_URL}><span
                                    className="btn__text">Sign up free</span></a> }
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

Feature.propTypes = {
    featureName: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
    featureImage: PropTypes.element.isRequired,
    isSecondary: PropTypes.bool.isRequired,
    additionalClassName: PropTypes.string,
    children: PropTypes.any.isRequired
};

export default Feature;