import React from 'react';
import imageSource from '../../images/device-4.webp';
import imageMain from '../../images/device-4.png';

const DeviceImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" className="unmarg--bottom box-shadow-wide" alt="Image" src={imageMain}/>
    </picture>
  );
};

export default DeviceImage;
