import React from 'react';
import Premium2019AwardImage from '../image/award/premium2019AwardImage';
import RisingStar2019AwardImage from '../image/award/risingStar2019AwardImage';

const Awards = () => {
  return (
    <section className="text-center">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-10 col-lg-12">
            <h2>Ready to get the most out of meetings?</h2>
            <p className="lead-smaller">
            Try for free. No credit card required
            </p>
            <div className="container hidden-xs">
              <form className="row justify-content-center cta-form" action={process.env.REGISTER_URL}>
                <label for="email-form-awards1">
                  <input className="validate-required validate-email" type="email" id="email-form-awards1" name="email" placeholder="Enter your email..."/>
                </label>
                <input type="submit" value="Sign up free" className="cta_button type--uppercase"/>
              </form>
            </div>
            <div className="container visible-xs">
              <form className="row justify-content-center cta-form" action={process.env.REGISTER_URL}>
                <label for="email-form-awards2">
                  <input className="validate-required validate-email-xs" type="email" id="email-form-awards2" name="email" placeholder="Enter your email..."/>
                </label>
                <input type="submit" value="Sign up free" className="cta-button-xs type--uppercase"/>
              </form>
            </div>

            <div className="container" style={{ 'marginTop': '50px' }}>
              <Premium2019AwardImage/>
              <RisingStar2019AwardImage/>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Awards;
