import React from 'react';
import imageSource from '../../../images/integration/whereby.webp';
import imageMain from '../../../images/integration/whereby.png';

const WherebyIntegrationImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" alt="Whereby" title="Whereby" className="image--xs" src={imageMain}/>
    </picture>
  );
};

export default WherebyIntegrationImage;
