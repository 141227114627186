import React from 'react';
import imageSource from '../../../images/integration/integromat.webp';
import imageMain from '../../../images/integration/integromat.png';

const IntegromatIntegrationImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" alt="Integromat" title="Integromat" className="image--xs" src={imageMain}/>
    </picture>
  );
};

export default IntegromatIntegrationImage;
