import React from 'react';
import imageMain from '../../../images/testimonial/tes3.jpg';
import imageSource from '../../../images/testimonial/tes3.webp';

const Testimonial3Image = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" className="testimonial__image" alt="Image" src={imageMain}/>
    </picture>
    
  );
};

export default Testimonial3Image;
