import React from 'react';
import imageSource from '../../../images/integration/office365.webp';
import imageMain from '../../../images/integration/office365.png';

const OfficeIntegrationImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" alt="Office 365 Calendar" title="Office 365 Calendar" className="image--xs" height="30" src={imageMain}/>
    </picture>
  );
};

export default OfficeIntegrationImage;
