import React from 'react';
import Feature from './feature';
import NeverFeatureImage from '../image/feature/neverFeatureImage';
import SeamlessFeatureImage from '../image/feature/seamlessFeatureImage';
import AvoidFeatureImage from '../image/feature/avoidFeatureImage';
import IntegrationsFeatureImage from '../image/feature/integrationsFeatureImage';
import ZoomIntegrationFeatureImage from '../image/feature/zoomIntegrationFeatureImage';

import Testimonial from './testimonial';
import Testimonial1Image from '../image/testimonial/testimonial1Image';
import Testimonial3Image from '../image/testimonial/testimonial3Image';
import Testimonial3CompanyImage from '../image/testimonial/testimonial3CompanyImage';
import Testimonial5Image from '../image/testimonial/testimonial5Image';
import Testimonial5CompanyImage from '../image/testimonial/testimonial5CompanyImage';

const FeatureList = () => {
  let featureNumber = 0;

  return (
    <>
      <Feature featureName="Delegate scheduling"
        additionalClassName="bg--purple"
        isSecondary={false}
        featureImage={<NeverFeatureImage/>}>
          Allow the invitees to choose from available meeting times. No action needed from your side. Just <strong>send an invitation link.</strong>
      </Feature>

    

      <Testimonial personName="Hans van Gent"
          personCompany="Founder and Chief Strategist at User Growth"
          personImage={<Testimonial5Image/>}
          companyLogo={<Testimonial5CompanyImage/>}>
          With Harmonizely, I have a beautiful, <b>on-brand</b>, on my  <b>own domain</b> experience, that lets client's book meetings straight into my calendar whenever suits for both of us. Oh, and did I mention the  <b>excellent support</b> and quickness to add new features?
      </Testimonial>

      <Feature featureName="Integrate all calendars"
        isSecondary={featureNumber++ % 2 === 0}
        additionalClassName="bg--yellow"
        featureImage={<SeamlessFeatureImage/>}>
          Connect Google, Outlook/Office 365, iCloud, Zimbra, Zoho and other <strong>CalDAV solutions</strong>.<br/>Choose an <strong>all-in meeting scheduler</strong> to rule them all.
      </Feature>
      <Testimonial personName="Chris Hardie"
          personCompany="Director of Special Projects at Automattic"
          personImage={<Testimonial3Image/>}
          companyLogo={<Testimonial3CompanyImage/>}>
          So far it's the <b>only well-designed tool</b> in this category I can find that doesn't assume Google Calendar and supports the CalDAV standard for syncing.
      </Testimonial>
      <Feature featureName="Manage all meetings in one place"
      additionalClassName="bg--yellow"
               isSecondary={featureNumber++ % 2 === 0}
               featureImage={<AvoidFeatureImage/>}>
               Access all meetings and calendars from <strong>one scheduling app</strong>. Save time and avoid confusion.
      </Feature>
      
      <Feature featureName="Connect your favorite apps"
               isSecondary={featureNumber++ % 2 === 0}
               featureImage={<IntegrationsFeatureImage/>}>
               Integrate Harmonizely with other popular platforms. The list of integrations includes Slack, MailChimp, Google Sheets, Pipedrive, Wordpress and many more!
      </Feature>
      <Testimonial personName="Karol Wójciszko"
          personCompany="CEO at Fresh Apps"
          personImage={<Testimonial1Image/>}>
          Harmonizely helps me <b>speed up</b> the process of scheduling customer calls and interviews with potentials employees which in turn <b>converts into more leads</b>.
      </Testimonial>
      <Feature featureName="Schedule video conferences"
      additionalClassName="bg--purple"
               isSecondary={featureNumber++ % 2 === 0}
               featureImage={<ZoomIntegrationFeatureImage/>}>
               Our Zoom, Microsoft Teams, Whereby, Kopano Meet or Google Meet integration generates a unique video conference link for every meeting.
      </Feature>
    </>
  );
};

export default FeatureList;
