import React from 'react';
import imageSource from '../../../images/integration/email_google.webp';
import imageMain from '../../../images/integration/email_google.svg';

const EmailGoogleIntegrationImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" alt="Google Calendar" title="Google Calendar" className="image--xs" height="30" src={imageMain}/>
    </picture>
  );
};

export default EmailGoogleIntegrationImage;
