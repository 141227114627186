import React from 'react';
import imageMain from '../../../images/integration/zoom.png';

const ZoomIntegrationImage = () => {
  return (
    <img loading="lazy" alt="Zoom" title="Zoom" className="image--xxs" src={imageMain}/>
  );
};

export default ZoomIntegrationImage;
