import React from 'react';
import imageSource from '../../../images/testimonial/tes3-company.webp';
import imageMain from '../../../images/testimonial/usergrowth.svg';

const Testimonial5CompanyImage = () => {
  return (
    <picture>
      {/* <source type="image/webp" srcSet={imageSource}/> */}
      <img loading="lazy" alt="UserGrowth" style={{ 'width': '125px' }} src={imageMain}/>
    </picture>
  );
};

export default Testimonial5CompanyImage;
