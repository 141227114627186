import React from 'react';
import Layout from '../components/layout';
import Awards from '../components/indexPage/awards';
import Integrations from '../components/indexPage/integrations';
import FeatureList from '../components/indexPage/featureList';
import Brands from '../components/indexPage/brands';
import AboutApp from '../components/indexPage/aboutApp';
import SEO from '../components/seo';

const IndexPage = () => {
  return (
    <Layout>
      <SEO title="#1 CalDAV Appointments Scheduling Tool!"/>
      <AboutApp/>
      <Brands/>
      <FeatureList/>
      <Integrations/>
      <Awards/>
    </Layout>
  );
};

export default IndexPage;
