import React from 'react';
import EmailOutlookIntegrationImage from '../image/integration/emailOutlookIntegrationImage';
import OfficeIntegrationImage from '../image/integration/officeIntegrationImage';
import IcloudIntegrationImage from '../image/integration/icloudIntegrationImage';
import EmailGoogleIntegrationImage from '../image/integration/emailGoogleIntegrationImage';
import FastmailIntegrationImage from '../image/integration/fastmailIntegrationImage';
import EmailNextcloudIntegrationImage from '../image/integration/emailNextcloudIntegrationImage';
import EmailFruuxIntegrationImage from '../image/integration/emailFruuxIntegrationImage';
import WordpressIntegrationImage from '../image/integration/wordpressIntegrationImage';
import WherebyIntegrationImage from '../image/integration/wherebyIntegrationImage';
import ZoomIntegrationImage from '../image/integration/zoomIntegrationImage';
import ZapierIntegrationImage from '../image/integration/zapierIntegrationImage';
import ZohoIntegrationImage from '../image/integration/zohoIntegrationImage';
import ZimbraIntegrationImage from '../image/integration/zimbraIntegrationImage';
import EvidenceIntegrationImage from '../image/integration/evidenceIntegrationImage';
import IntegromatIntegrationImage from '../image/integration/integromatIntegrationImage';
import MsTeamsImage from '../image/integration/msTeamsImage';
import FacebookPixelImage from '../image/integration/facebookPixelImage';
import KopanoImage from '../image/integration/kopanoImage';

const Integrations = () => {
  return (
    <section className="text-center">
      <div className="container">
        <div className="row justify-content-around">
          <div className="col-md-10 col-lg-12">
            <h2>Scheduling integrations</h2>
            <p className="lead">
            Connect Harmonizely with your favourite tools:
            </p>
            <div className="row">
              <div className="col-md-12">
                <div className="list-inline integrations-list">
                  <div className="integration-image">
                    <EmailOutlookIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <OfficeIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <MsTeamsImage/>
                  </div>
                  <div className="integration-image">
                    <IcloudIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <EmailGoogleIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <FastmailIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <EmailNextcloudIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <EmailFruuxIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <WordpressIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <WherebyIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <ZoomIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <ZapierIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <ZohoIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <ZimbraIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <EvidenceIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <IntegromatIntegrationImage/>
                  </div>
                  <div className="integration-image">
                    <FacebookPixelImage/>
                  </div>
                  <div className="integration-image">
                    <KopanoImage/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Integrations;
