import React from 'react';
import imageSource from '../../../images/integration/zoho.webp';
import imageMain from '../../../images/integration/zoho.png';

const ZohoIntegrationImage = () => {
  return (
    <picture>
      <source type="image/webp" srcSet={imageSource}/>
      <img loading="lazy" alt="Zoho" title="Zoho" className="image--xxs" src={imageMain}/>
    </picture>
  );
};

export default ZohoIntegrationImage;
