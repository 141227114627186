import React from "react"
import DeviceImage from "../image/deviceImage"
import LeadImage from "../image/leadImage"
import "../../css/index.css"

const AboutApp = () => {
  return (
    <section className="cover text-center unpad--bottom">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div style={{ margin: 0 }}>
              <h1 className="h2 inline-block">
                Schedule meetings
                <br />
                in a few clicks
              </h1>
            </div>
            <p className="lead-small">
              Save time and avoid unnecessary emails.
              <br />
              Boost engagement and conversion rates.
            </p>
            <br />
          </div>
        </div>
        <div className="container hidden-xs" style={{ paddingBottom: "35px" }}>
          <form
            className="row justify-content-center cta-form"
            action={process.env.REGISTER_URL}
          >
            <label for="email-form1">
              <input
                className="validate-required validate-email"
                type="email"
                id="email-form1"
                name="email"
                placeholder="Enter your email..."
              />
            </label>
            <input type="submit" value="Sign up free" className="cta_button" />
          </form>
          <p className="lead-smaller d-inline-flex">
            <span className="check-item margin-right-40">
              <span className="check-icon-small" />
              <span>Get started for free.</span>
            </span>
            <span className="check-item">
              <span className="check-icon-small" />
              <span>No credit card required.</span>
            </span>
          </p>
        </div>
        <div className="container visible-xs" style={{ paddingBottom: "25px" }}>
          <form
            className="justify-content-center cta-form"
            action={process.env.REGISTER_URL}
          >
            <label for="email-form2">
              <input
                className="validate-required validate-email-xs"
                type="email"
                id="email-form2"
                name="email"
                placeholder="Enter your email..."
              />
            </label>
            <input
              type="submit"
              value="Sign up free"
              className="cta-button-xs"
            />
            <label id="email-label2"></label>
          </form>
          <p className="lead-smaller">
            <span className="check-item margin-right-12">
              <span className="check-icon-small" />
              <span>Get started for free.</span>
            </span>
            <span className="check-item">
              <span className="check-icon-small" />
              <span>No credit card required.</span>
            </span>
          </p>
        </div>
        <div className="row justify-content-center">
          <div className="col-md-8">
            <DeviceImage />
            <div className="bg--dark box-shadow-wide">
              <LeadImage />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default AboutApp
